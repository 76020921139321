import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import prj from "./modules/prj";
import base from "./modules/baseConf";
import iframeCheck from "./modules/iframeCheck";
import persistedState from 'vuex-persistedstate';
Vue.use(Vuex);
var store = new Vuex.Store({
  plugins: [persistedState()],
  modules: {
    user: user,
    prj: prj,
    iframeCheck: iframeCheck,
    base: base
  }
});
export default store;