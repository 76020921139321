import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
export default {
  getprjList: function getprjList() {
    return request.get('/app/v1/prj-home-page/get-all-prj').then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  issueList: function issueList(params, pageNo, pageSize) {
    //查询问题列表接口
    return request.post('/app-doc-center/v1/problem/manage/cad-list-gml?pageNo=' + pageNo + '&pageSize=' + pageSize, params).then(function (res) {
      return res;
    });
  },
  issueList_nogml: function issueList_nogml(params, pageNo, pageSize) {
    //查询问题列表接口
    return request.post('/app-doc-center/v1/problem/manage/cad-list?pageNo=' + pageNo + '&pageSize=' + pageSize, params).then(function (res) {
      return res;
    });
  },
  issueList3D: function issueList3D(params, pageNo, pageSize) {
    //查询问题列表接口 模型审核
    return request.post('/app-doc-center/v1/qm-comment/page-list-gml?pageNo=' + pageNo + '&pageSize=' + pageSize, params).then(function (res) {
      return res;
    });
  },
  issueList3D_nogml: function issueList3D_nogml(params, pageNo, pageSize) {
    //查询问题列表接口 模型审核
    return request.post('/app-doc-center/v1/qm-comment/page-list?pageNo=' + pageNo + '&pageSize=' + pageSize, params).then(function (res) {
      return res;
    });
  },
  getLoadLightModel: function getLoadLightModel(params) {
    //跳转文件
    return request.post('/app-doc-center/v1/doc/getLoadLightModel-gml', params).then(function (res) {
      return res;
    });
  },
  getLoadLightModeloffice: function getLoadLightModeloffice(fileId, versionId) {
    //跳转非模型图纸文件
    return request.get('/app-doc-center/v1/wopi/getPreviewUrl-gml?fileId=' + fileId + '&versionId=' + versionId).then(function (res) {
      return res;
    });
  },
  getProblemDetail: function getProblemDetail(params) {
    return request.post("/app-doc-center/v1/drawing/problem/detail/".concat(params)).then(function (res) {
      return res;
    });
  },
  getStageId: function getStageId(prjID, folderId) {
    //获取虚拟点评文件夹id
    return request.get('/app-doc-center/v1/doc/query-id-by-oldid?prjID=' + prjID + '&folderId=' + folderId).then(function (res) {
      return res;
    });
  },
  cadDelete: function cadDelete(qMId) {
    return request.post("/app-doc-center/v1/problem/manage/cad-delete-gml/".concat(qMId)).then(function (res) {
      return res;
    });
  },
  cadDelete_nogml: function cadDelete_nogml(qMId) {
    return request.post("/app-doc-center/v1/problem/manage/cad-delete/".concat(qMId)).then(function (res) {
      return res;
    });
  },
  delectProData3D: function delectProData3D(qMID) {
    //获取删除问题id
    return request.post("/app-doc-center/v1/qm-comment/delete-gml/".concat(qMID)).then(function (res) {
      return res;
    });
  },
  delectProData3D_nogml: function delectProData3D_nogml(qMID) {
    //获取删除问题id
    return request.post("/app-doc-center/v1/qm-comment/delete/".concat(qMID)).then(function (res) {
      return res;
    });
  },
  //获取阶段
  queryGetStage: function queryGetStage(id, isFolder) {
    return request.post("/app-doc-center/v1/doc/query-stage?id=".concat(id, "&isFolder=").concat(isFolder)).then(function (res) {
      return res;
    });
  },
  //获取阶段 3D
  queryCommentFolder3D: function queryCommentFolder3D(prjID) {
    return request.get('/app-doc-center/v1/doc/query-comment-stage-gml?prjID=' + prjID).then(function (res) {
      return res;
    });
  },
  queryCommentFolder3D_nogml: function queryCommentFolder3D_nogml(prjID) {
    return request.get('/app-doc-center/v1/doc/query-comment-stage?prjID=' + prjID).then(function (res) {
      return res;
    });
  },
  repaQueStatus: function repaQueStatus(params) {
    //表格内问题状态修改
    return request.post('/app-doc-center/v1/drawing/problem/status-edit', params).then(function (res) {
      return res;
    });
  },
  queryDict: function queryDict(priID, queCategory) {
    //查询字典
    return request.get('/app-doc-center/v1/drawing/problem/add-init?prjId=' + priID + '&queCategory=' + queCategory).then(function (res) {
      return res;
    });
  },
  queryDict3D: function queryDict3D(prjId) {
    //查询字典 3D
    return request.get('/app-doc-center/v1/qm-comment/dic-init?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  // 文档审核获取阶段
  getFolderStage: function getFolderStage(prjId) {
    //查询该项目的阶段
    return request.get('/app-doc-center/v1/doc/folder-stage/list-gml?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  // 2D审核获取阶段
  getFolderStage_2D: function getFolderStage_2D(prjId) {
    //查询该项目的阶段
    return request.get('/app-doc-center/v1/doc/query-2d-stage-gml?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  getFolderStage2D_nogml: function getFolderStage2D_nogml(prjId) {
    //查询该项目的阶段 
    return request.get('/app-doc-center/v1/doc/stage/list?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  getFolderStage3D_nogml: function getFolderStage3D_nogml(prjId) {
    //查询该项目的阶段 
    return request.get('/app-doc-center/v1/doc/query-comment-stage?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  getFolderStageDOC_nogml: function getFolderStageDOC_nogml(prjId) {
    //查询该项目的阶段 
    return request.get('/app-doc-center/v1/doc/folder-stage/list?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  getProForm: function getProForm(prjID, folderID) {
    //获取版本
    return request.get('/app-doc-center/v1/doc/edition/query-gml?prjID=' + prjID + '&folderID=' + folderID).then(function (res) {
      return res;
    });
  },
  getProForm_nogml: function getProForm_nogml(prjID, folderID) {
    //获取版本
    return request.get('/app-doc-center/v1/doc/edition/query?prjID=' + prjID + '&folderID=' + folderID).then(function (res) {
      return res;
    });
  },
  moreQueStatus: function moreQueStatus(params) {
    //批量问题处理
    return request.post('/app-doc-center/v1/problem/manage/cad-queStatus/batch-edit-gml', params).then(function (res) {
      return res;
    });
  },
  moreQueStatus_nogml: function moreQueStatus_nogml(params) {
    //批量问题处理
    return request.post('/app-doc-center/v1/problem/manage/cad-queStatus/batch-edit', params).then(function (res) {
      return res;
    });
  },
  moreQueStatus3D: function moreQueStatus3D(params) {
    //批量问题处理
    return request.post('/app-doc-center/v1/qm-comment/batch-update-gml', params).then(function (res) {
      return res;
    });
  },
  moreQueStatus3D_nogml: function moreQueStatus3D_nogml(params) {
    //批量问题处理
    return request.post('/app-doc-center/v1/qm-comment/batch-update', params).then(function (res) {
      return res;
    });
  },
  moreQueStatusAll: function moreQueStatusAll(params) {
    //批量问题处理all
    return request.post('/app-doc-center/v1/problem/manage/queStatus/batch-edit-all', params).then(function (res) {
      return res;
    });
  },
  moreProvideParty: function moreProvideParty(params) {
    //批量处理提资方
    return request.post('/app-doc-center/v1/problem/manage/provideParty/batch-edit', params).then(function (res) {
      return res;
    });
  },
  moreProvidePartyAll: function moreProvidePartyAll(params) {
    //批量处理提资方all
    return request.post('/app-doc-center/v1/problem/manage/provideParty/batch-edit-all', params).then(function (res) {
      return res;
    });
  },
  moreModifyRadio: function moreModifyRadio(params) {
    //批量处理修改方
    return request.post('/app-doc-center/v1/problem/manage/cad-modifyParty/batch-edit-gml', params).then(function (res) {
      return res;
    });
  },
  moreModifyRadio_nogml: function moreModifyRadio_nogml(params) {
    //批量处理修改方
    return request.post('/app-doc-center/v1/problem/manage/cad-modifyParty/batch-edit', params).then(function (res) {
      return res;
    });
  },
  moreModifyRadioAll: function moreModifyRadioAll(params) {
    //批量处理修改方all
    return request.post('/app-doc-center/v1/problem/manage/modifyParty/batch-edit-all', params).then(function (res) {
      return res;
    });
  },
  morefinishTime: function morefinishTime(params) {
    //批量处理完成时间
    return request.post('/app-doc-center/v1/problem/manage/cad-requireFinishTime/batch-edit-gml', params).then(function (res) {
      return res;
    });
  },
  morefinishTime_nogml: function morefinishTime_nogml(params) {
    //批量处理完成时间
    return request.post('/app-doc-center/v1/problem/manage/cad-requireFinishTime/batch-edit', params).then(function (res) {
      return res;
    });
  },
  morefinishTimeAll: function morefinishTimeAll(params) {
    //批量处理完成时间all
    return request.post('/app-doc-center/v1/problem/manage/requireFinishTime/batch-edit-all', params).then(function (res) {
      return res;
    });
  },
  getSelectData: function getSelectData(params) {
    //获取筛选的数据
    return request.post('/app-doc-center/v1/problem/manage/cad-prjSysDic/list-gml', params).then(function (res) {
      return res;
    });
  },
  getSelectData_nogml: function getSelectData_nogml(params) {
    //获取筛选的数据
    return request.post('/app-doc-center/v1/problem/manage/cad-prjSysDic/list', params).then(function (res) {
      return res;
    });
  },
  getSelectDataSelect3D: function getSelectDataSelect3D(prjID, docVersionID) {
    //获取筛选的数据 	
    return request.get('/app-doc-center/v1/qm-comment/init-query-field-gml?prjID=' + prjID + "&docVersionID=" + docVersionID).then(function (res) {
      return res;
    });
  },
  getSelectDataSelect3D_nogml: function getSelectDataSelect3D_nogml(prjID, docVersionID) {
    //获取筛选的数据 	
    return request.get('/app-doc-center/v1/qm-comment/init-query-field?prjID=' + prjID + "&docVersionID=" + docVersionID).then(function (res) {
      return res;
    });
  },
  exportExcel: function exportExcel(params) {
    //导出excel   
    return request.post('/app-doc-center/v1/problem/manage/cad-export-excel-gml', params);
  },
  exportExcel_nogml: function exportExcel_nogml(params) {
    //导出excel   
    return request.post('/app-doc-center/v1/problem/manage/cad-export-excel', params);
  },
  exportExcel3D: function exportExcel3D(params) {
    //导出excel   
    return request.post('/app-doc-center/v1/qm-comment/check-export-excel-gml', params).then(function (res) {
      return res;
    });
  },
  exportExcel3D_nogml: function exportExcel3D_nogml(params) {
    //导出excel   
    return request.post('/app-doc-center/v1/qm-comment/check-export-excel', params).then(function (res) {
      return res;
    });
  },
  problemDetail: function problemDetail(qMId) {
    //查询项目详情
    return request.get("/app-doc-center/v1/problem/manage/cad-detail-gml/".concat(qMId)).then(function (res) {
      return res;
    });
  },
  problemDetail_nogml: function problemDetail_nogml(qMId) {
    //查询项目详情
    return request.get("/app-doc-center/v1/problem/manage/details/".concat(qMId)).then(function (res) {
      return res;
    });
  },
  problemDetail3D: function problemDetail3D(params) {
    //查询项目详情 
    return request.get("/app-doc-center/v1/qm-comment/query-detail-gml/".concat(params)).then(function (res) {
      return res;
    });
  },
  problemDetail3D_nogml: function problemDetail3D_nogml(params) {
    //查询项目详情 
    return request.get("/app-doc-center/v1/qm-comment/details/".concat(params)).then(function (res) {
      return res;
    });
  },
  submitDetail: function submitDetail(params) {
    //获取修改详情内容页面
    return request.post('/app-doc-center/v1/problem/manage/cad-detail/edit-gml', params).then(function (res) {
      return res;
    });
  },
  submitDetail_nogml: function submitDetail_nogml(params) {
    //获取修改详情内容页面
    return request.post('/app-doc-center/v1/problem/manage/cad-detail/edit', params).then(function (res) {
      return res;
    });
  },
  submitDetail3D: function submitDetail3D(params) {
    //获取修改详情内容页面
    return request.post('/app-doc-center/v1/qm-comment/update-gml', params).then(function (res) {
      return res;
    });
  },
  submitDetail3D_nogml: function submitDetail3D_nogml(params) {
    //获取修改详情内容页面
    return request.post('/app-doc-center/v1/qm-comment/update', params).then(function (res) {
      return res;
    });
  },
  delectProData: function delectProData(params) {
    //获取删除问题id
    return request.post("/app-doc-center/v1/drawing/problem/delete/".concat(params)).then(function (res) {
      return res;
    });
  },
  queryGetQuestionDetail: function queryGetQuestionDetail(qMId) {
    //点击查看详情
    return request.get("/app-doc-center/v1/drawing/problem/detail/".concat(qMId)).then(function (res) {
      return res;
    });
  },
  getRoom: function getRoom(fileID) {
    return request.get('/app/v1/vc-room/list?fileID=' + fileID).then(function (res) {
      return res;
    });
  },
  getRevitList: function getRevitList(params) {
    // 获取瑞斯图审图list
    return request.post('/app/v1/rasidrawing/query', params).then(function (res) {
      return res;
    });
  },
  getFolderList: function getFolderList(prjId) {
    return request.get('/app/v1/rasidrawing/list-dir?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  importExecl: function importExecl(prjId) {
    return request.get('/app/v1/rasidrawing/upload-model?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  getIsExcelExist: function getIsExcelExist(params) {
    //导出excel-是否能导出
    return request.get("/app-doc-center/v1/drawing/exportExcel/is-excel-exist/".concat(params)).then(function (res) {
      return res;
    });
  },
  queryTree: function queryTree(data) {
    return request.post('/app-doc-center/v1/doc/queryList-gml', data).then(function (res) {
      return res;
    });
  },
  queryTree_nogml: function queryTree_nogml(data) {
    return request.post('/app-doc-center/v1/doc/queryList', data).then(function (res) {
      return res;
    });
  },
  queryCADFolderID: function queryCADFolderID(prjID, folderId) {
    return request.get("/app-doc-center/v1/doc/query-id-by-oldid?prjID=".concat(prjID, "&folderId=").concat(folderId)).then(function (res) {
      return res;
    });
  },
  getChannel: function getChannel(prjId) {
    return request.get("/app/v1/prj-home-page/get-one-prj?prjId=".concat(prjId)).then(function (res) {
      return res;
    });
  },
  getPrjPerson: function getPrjPerson(prjId) {
    return request.get("/app/v1/iamUserInfo/getPrjPersonPlanRoleType?prjId=".concat(prjId)).then(function (res) {
      return res;
    });
  },
  queryCommentList: function queryCommentList(typeId, relaID) {
    return request.get('/app/v1/comment/list-gml?typeId=' + typeId + "&relaID=" + relaID).then(function (res) {
      return res;
    });
  },
  queryCommentList_nogml: function queryCommentList_nogml(typeId, relaID) {
    return request.get('/app/v1/comment/list?typeId=' + typeId + "&relaID=" + relaID).then(function (res) {
      return res;
    });
  },
  //图模,批注通用接口
  queryPrjUser: function queryPrjUser(prjID) {
    return request.get('/app/v1/iamUserInfo/getUserInfoByPrjId-gml?prjId=' + prjID).then(function (res) {
      return res;
    });
  },
  createComment: function createComment(params) {
    return request.post('/app/v1/comment/save-gml', params).then(function (res) {
      return res;
    });
  },
  createComment_nogml: function createComment_nogml(params) {
    return request.post('/app/v1/comment/save', params).then(function (res) {
      return res;
    });
  },
  getTimelineData: function getTimelineData(id) {
    //获取活动时间线
    return request.get("/app-doc-center/v1/problem/manage/cad-query-record-gml/".concat(id)).then(function (res) {
      return res;
    });
  },
  getTimelineData_nogml: function getTimelineData_nogml(id) {
    //获取活动时间线
    return request.get("/app-doc-center/v1/problem/manage/cad-query-record/".concat(id)).then(function (res) {
      return res;
    });
  },
  getTimelineData3D: function getTimelineData3D(params) {
    //获取活动时间线
    return request.get("/app-doc-center/v1/qm-comment/qm-oper-list-gml/".concat(params)).then(function (res) {
      return res;
    });
  },
  getTimelineData3D_nogml: function getTimelineData3D_nogml(params) {
    //获取活动时间线
    return request.get("/app-doc-center/v1/qm-comment/qm-oper-list/".concat(params)).then(function (res) {
      return res;
    });
  },
  getAnnotationList: function getAnnotationList(paras, pageNo, pageSize) {
    //分页查询批注
    return request.post('/app-doc-center/v1/prj-annotation/getFileNameList-gml?pageNo=' + pageNo + '&pageSize=' + pageSize, paras).then(function (res) {
      return res;
    });
  },
  getAnnotationList_nogml: function getAnnotationList_nogml(paras, pageNo, pageSize) {
    //分页查询批注
    return request.post('/app-doc-center/v1/prj-annotation/getFileNameList?pageNo=' + pageNo + '&pageSize=' + pageSize, paras).then(function (res) {
      return res;
    });
  },
  getPrjAnnotationList: function getPrjAnnotationList(paras) {
    //根据文件id查询批注
    return request.post('/app-doc-center/v1/prj-annotation/getPrjAnnotationList-gml', paras).then(function (res) {
      return res;
    });
  },
  getPrjAnnotationList_nogml: function getPrjAnnotationList_nogml(paras) {
    //根据文件id查询批注
    return request.post('/app-doc-center/v1/prj-annotation/getPrjAnnotationList', paras).then(function (res) {
      return res;
    });
  },
  getAnnotationId: function getAnnotationId(paras) {
    //根据文件id查询批注id
    return request.post('/app-doc-center/v1/prj-annotation/getAnnotationId-gml', paras).then(function (res) {
      return res;
    });
  },
  getAnnotationId_nogml: function getAnnotationId_nogml(paras) {
    //根据文件id查询批注id
    return request.post('/app-doc-center/v1/prj-annotation/getAnnotationId', paras).then(function (res) {
      return res;
    });
  },
  getStatus: function getStatus() {
    //批注状态
    return request.get("/app-doc-center/v1/prj-annotation/status").then(function (res) {
      return res;
    });
  },
  getAllUsers: function getAllUsers(prjId) {
    //批注创建人
    return request.get('/app-doc-center/v1/prj-annotation/all-users-gml?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  getAllUsersByStageId: function getAllUsersByStageId(stageId, prjId) {
    //批注创建人
    return request.get('/app-doc-center/v1/prj-annotation/all-users-gml?stageId=' + stageId + '&prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  getAllUsers_nogml: function getAllUsers_nogml(prjId) {
    //批注创建人
    return request.get('/app-doc-center/v1/prj-annotation/all-users?prjId=' + prjId).then(function (res) {
      return res;
    });
  },
  exportExcelDOC: function exportExcelDOC(params) {
    //导出excel DOC
    return request.post('/app-doc-center/v1/prj-annotation/export-excel-gml', params).then(function (res) {
      return res;
    });
  },
  exportExcelDOC_nogml: function exportExcelDOC_nogml(params) {
    //导出excel DOC
    return request.post('/app-doc-center/v1/prj-annotation/export-excel', params).then(function (res) {
      return res;
    });
  },
  getAnnotationDetail: function getAnnotationDetail(annotationId, fileId) {
    return request.get("/app-doc-center/v1/prj-annotation/query-by-annotation-id-gml?annotationId=".concat(annotationId, "&fileId=").concat(fileId)).then(function (res) {
      return res;
    });
  },
  getAnnotationDetail_nogml: function getAnnotationDetail_nogml(annotationID) {
    return request.get("/app-doc-center/v1/prj-annotation/details/".concat(annotationID)).then(function (res) {
      return res;
    });
  },
  getImgURL: function getImgURL(prjId, fileMd5) {
    return request.get("/app-doc-center/v1/wopi/download/drawing-gml?prjId=".concat(prjId, "&fileMd5=").concat(fileMd5)).then(function (res) {
      return res;
    });
  },
  getProfessionTree: function getProfessionTree(prjId) {
    return request.get("/app-doc-center/v1/doc-statistics/get-prj-profession?prjId=".concat(prjId)).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getCustomDrawingNodeList: function getCustomDrawingNodeList(prjId) {
    return request.get("/app-doc-center/v1/cad/problem-statistics/getCustomDrawingNodeList?prjId=".concat(prjId)).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getFolderListByParentIds: function getFolderListByParentIds(params) {
    return request.post("/app-doc-center/v1/doc/getFolderListByParentIds", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getQmStageList: function getQmStageList(prjId) {
    return request.get("/app-doc-center/v1/doc/query-comment-stage?prjID=".concat(prjId)).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queLabelBatchEdit: function queLabelBatchEdit(params) {
    //决策
    return request.post("/app-doc-center/v1/problem/manage/cad-question-label/batch-edit-gml", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queLabelBatchEdit_nogml: function queLabelBatchEdit_nogml(params) {
    //决策
    return request.post("/app-doc-center/v1/problem/manage/cad-question-label/batch-edit", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queLabelBatchEditThree: function queLabelBatchEditThree(params) {
    return request.post("/app-doc-center/v1/qm-comment/addMakeDecisionLable-gml", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queLabelBatchEditThree_nogml: function queLabelBatchEditThree_nogml(params) {
    return request.post("/app-doc-center/v1/qm-comment/addMakeDecisionLable", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  uploadBaseImg: function uploadBaseImg(formData, prjId) {
    return request.post("/app-doc-center/v1/drawing/img-upload-gml/".concat(prjId), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  uploadBaseImg_nogml: function uploadBaseImg_nogml(formData, prjId) {
    return request.post("/app-doc-center/v1/drawing/img-upload/".concat(prjId), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  uploadImgToBase64: function uploadImgToBase64(file) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        // 图片转base64完成后返回reader对象
        resolve(reader);
      };

      reader.onerror = reject;
    });
  },
  loginout: function loginout() {
    return request.get("/app/v1/auth/logout").then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  transfer: function transfer(id) {
    return request.get("/app/v1/transfer/Code2Id?id=".concat(id)).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  }
};