import "F:\\@company\\lzz-productcheck-front\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "F:\\@company\\lzz-productcheck-front\\node_modules\\core-js\\modules\\es.promise.js";
import "F:\\@company\\lzz-productcheck-front\\node_modules\\core-js\\modules\\es.object.assign.js";
import "F:\\@company\\lzz-productcheck-front\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "F:\\@company\\lzz-productcheck-front\\node_modules\\core-js\\modules\\es.string.replace-all.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import App from './App.vue';
import elementUI from 'element-ui';
import maiaUI from '@longfor/maia-ui';
import '@longfor/maia-ui/src/assets/element-ui/index.css'; //element-ui引入该样式

import '@longfor/maia-ui/dist/styles/maia.css'; // 引入亮色主题样式

import router from './router/index.next';
import store from './store';
import '@/styles/reset.css';
import infiniteScroll from 'vue-infinite-scroll';
import splitPane from 'vue-splitpane';
import './longerPerson.js';
import bus from '@/utils/bus';
Vue.prototype.$bus = bus;
Vue.component('split-pane', splitPane); // 注册Frameyts全局组件

import Frameyts from 'Frameyts';
Vue.use(Frameyts);

var echarts = function echarts() {
  return import('echarts/lib/echarts');
};

Vue.prototype.$echarts = echarts;
import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside); // 与权限控制相关的全局方法

import allPerms from '@/utils/allPerms';
import permissionUtils from '@/utils/permission-utils';
Vue.prototype.$$ = allPerms;

Vue.prototype.$E = function (permission) {
  return permissionUtils.canAccess(permission);
};

import moment from 'moment';
Vue.prototype.$moment = moment;
import xss from 'xss';
Vue.prototype.xss = xss;
Vue.config.productionTip = false;
window.vue = Vue;
Vue.use(infiniteScroll);
Vue.use(elementUI);
Vue.use(maiaUI);
Vue.use(maiaUI, true, true);
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');