import _typeof from "F:/@company/lzz-productcheck-front/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import store from '@/store';
import { constantRouterMap } from '@/router'; // SWITCH ENABLE / DISABLE PERMISSION CHECK

function hasPermission(funcName) {
  //funcName有对象和字符串两种形式 加判断区分一下
  var funcNames = '';
  var funcNamesChild = '';

  if (_typeof(funcName) === 'object') {
    funcNames = funcName.name ? '/' + funcName.name : funcName.path;
    funcNamesChild = funcName.path; //获取子级的path
  } else {
    funcNames = funcName;
  }

  if (!funcNames) {
    return false;
  }

  var publics = constantRouterMap.map(function (item) {
    return item.path;
  });
  var publicsChildren = [];
  constantRouterMap.forEach(function (item) {
    if (item.children && item.children.length > 0) {
      publicsChildren = item.children.map(function (items) {
        return items.path;
      });
    }
  }); //判断父级和子级是否都包含

  if (publics.includes(funcNames) || publicsChildren.includes(funcNamesChild)) {
    // console.debug('funcName included in publics')
    return true;
  }

  var permissions = store.state.user.permissions || [];
  var ok = permissions.includes(funcNames); // console.debug('check permission returns: ', permissions.length, ok)

  return ok;
}

function canAccess(funcName) {
  return hasPermission(funcName);
}

function canNotAccess(funcName) {
  return !hasPermission(funcName);
}

function canAccessRouter(router) {
  var funcName = '';

  if (router.meta && router.meta.funcName) {
    funcName = router.meta.funcName;
  }

  if (!funcName) return true; // debugger

  return hasPermission(funcName);
}

export default {
  hasPermission: hasPermission,
  canAccess: canAccess,
  canNotAccess: canNotAccess,
  canAccessRouter: canAccessRouter
};