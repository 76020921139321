//
//
//
//
//
//
//
export default {
  name: 'App',
  data: function data() {
    return {
      status: 'none'
    };
  },
  methods: {
    doSomething: function doSomething() {
      this.status = 'finish';
    }
  },
  mounted: function mounted() {
    var loading = document.getElementById('loader-wrapper');

    if (loading) {
      loading.style.display = 'none';
    }
  }
};