import Cookies from 'js-cookie';
var iframeCheck = {
  state: {
    checkUrl: '',
    keyword: localStorage.getItem("KeyWord") || ""
  },
  mutations: {
    dealUrl: function dealUrl(state, url) {
      state.checkUrl = url;
    },
    clearLocal: function clearLocal(state) {
      state.keyword = '';
      localStorage.setItem("KeyWord", '');
    },
    setKeyWord: function setKeyWord(state, val) {
      state.keyword = val;
      localStorage.setItem("KeyWord", val);
    }
  },
  actions: {
    setUrl: function setUrl(_ref, url) {
      var commit = _ref.commit;
      commit('dealUrl', url);
    },
    clearKeyword: function clearKeyword(_ref2) {
      var commit = _ref2.commit;
      commit('clearLocal');
    },
    setKey: function setKey(_ref3, val) {
      var commit = _ref3.commit;
      commit('setKeyWord', val);
    }
  },
  getters: {
    getKeyWord: function getKeyWord(state) {
      if (localStorage.getItem('KeyWord')) {
        state.keyword = localStorage.getItem('KeyWord') || "";
      }

      return state.keyword;
    }
  }
};
export default iframeCheck;