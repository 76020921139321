import Cookies from 'js-cookie';
var prj = {
  state: {
    prjId: Cookies.get('coePrjId'),
    isNew: true,
    navTab: '0',
    prjBuId: '',
    spaceId: ''
  },
  mutations: {
    dealPrjId: function dealPrjId(state, id) {
      state.prjId = id;
      Cookies.set('coePrjId', id);
    },
    dealNew: function dealNew(state, boolean) {
      state.isNew = boolean;
    },
    dealNav: function dealNav(state, index) {
      state.navTab = index;
    },
    dealBuId: function dealBuId(state, val) {
      state.prjBuId = val;
    },
    dealSpaceId: function dealSpaceId(state, id) {
      state.spaceId = id;
    }
  },
  actions: {
    setPrjId: function setPrjId(_ref, id) {
      var commit = _ref.commit;
      commit('dealPrjId', id);
    },
    setIsNew: function setIsNew(_ref2, boolean) {
      var commit = _ref2.commit;
      commit('dealNew', boolean);
    },
    setNav: function setNav(_ref3, index) {
      var commit = _ref3.commit;
      commit('dealNav', index);
    },
    setBuId: function setBuId(_ref4, val) {
      var commit = _ref4.commit;
      commit('dealBuId', val);
    },
    setSpaceId: function setSpaceId(_ref5, id) {
      var commit = _ref5.commit;
      commit('dealSpaceId', id);
    }
  }
};
export default prj;