export default {
  state: {
    isLoading: false,
    appLoading: false,
    count: 0
  },
  mutations: {
    setLoading: function setLoading(state, isLoading) {
      //引用计数
      // debugger
      // if (isLoading) state.count += 1
      // else state.count -= 1
      // if (!isLoading && state.count !== 0) {
      //     state.count = 0
      //     return
      // }
      state.isLoading = isLoading;
    },
    setAppLoading: function setAppLoading(state, isAppLoading) {
      state.isAppLoading = isAppLoading;
    }
  },
  actions: {
    onLoading: function onLoading(context, isLoading) {
      context.commit('setLoading', isLoading);
    },
    onAppLoading: function onAppLoading(context, isAppLoading) {
      context.commit('setAppLoading', isAppLoading);
    }
  }
};