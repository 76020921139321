export default {
  // ADMIN_APP: 'admin:app',
  // ADMIN_FORM: 'admin:form',
  // USER_ADD: 'user:add',
  // USER_ROLE_ADD: 'user:role:add',
  // PROJECT_LIST:'FC_HALO_XMLBold',
  // PROJECT_MAIN:'FC_HALO_HALO3XMGL3XMSY',
  // PERMS_END: '<DO-NOT-USE>',
  // SYSTEM_HOME:'FC_HALO_HALO3PTSY',//平台首页
  // SYSTEM_HOME2:'FC_HALO_HALO3PTSY2',//首页2加权限
  // SYSTEM_MG:'FM_HALO_HALO3XMGL',//项目管理
  // PROJECT_DOC:'FC_HALO_HALO3XMGL3XMWD', //项目文档
  // PROJECT_PERSONS:'FC_HALO_HALO3XMGL3XMRY', //项目人员
  // PROJECT_VIRTUALRMARK:'FC_HALO_HALO3XMGL3XNDP', //项目虚拟点评
  ADMIN_MEAU_TMGL: 'FC_PCS_TMGL',
  //图模管理
  ADMIN_MEAU_YYXZ: 'FC_PCS_YYXZ',
  //应用下载
  ADMIN_MEAU_JTKBX: 'FC_PCS_JTKB',
  //集团看板
  ADMIN_MEAU_JTKBJ: 'FC_PCS_JTKBJ',
  //集团看板(旧)
  ADMIN_MEAU_NBRE: 'FC_PCS_NBRE',
  //内部人员
  ADMIN_MEAU_QXGL: 'FC_PCS_QXGL',
  //权限设置
  ADMIN_MEAU_WBRY: 'FC_PCS_WBRY',
  //外部人员
  ADMIN_MEAU_WTGL: 'FC_PCS_WTGL',
  //问题管理
  ADMIN_MEAU_GZPZ: 'FC_PCS_GZPZ',
  //规则配置
  ADMIN_MEAU_DQTJ: 'FC_PCS_DQTJ',
  //地区统计
  ADMIN_MEAU_GFTJ: 'FC_PCS_GFTJ',
  //供方统计
  ADMIN_MEAU_JTTJ: 'FC_PCS_JTTJ',
  //集团统计
  ADMIN_MEAU_XMTJ: 'FC_PCS_XMTJ',
  //项目统计
  ADMIN_MEAU_GZJ: 'FC_PCS_GZJ',
  //规则集
  ADMIN_MEAU_WTK: 'FC_PCS_WTK',
  //问题库
  ADMIN_MEAU_GZMB: 'FC_PCS_GZMB',
  //规则模板
  ADMIN_MEAU_GZTW: 'FC_PCS_GZTW',
  //规则条文
  ADMIN_MEAU_GZZD: 'FC_PCS_GZZD',
  //规则字典
  ADMIN_MEAU_BJGL: 'FC_PCS_BJGL',
  //标签管理
  ADMIN_MEAU_YFH: 'FC_PCS_YFH',
  //预复核
  ADMIN_MENU_FHLB: 'FC_PCS_FHLB',
  //复核列表
  ADMIN_MENU_BSQD: 'FC_PCS_BSQD',
  //必审清单
  ADMIN_MENU_BSQDTJ: 'FC_PCS_BSQDTJ',
  // 必审清单统计
  ADMIN_MENU_BSQDWH: 'FC_PCS_BSQDWH',
  // 必审清单维护
  ADMIN_MENU_STYDGL: 'FC_PCS_STYDGL',
  // 审图要点管理
  ADMIN_MENU_ZDGL: 'FC_PCS_ZDGL' // 字典管理

};