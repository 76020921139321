import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import { getToken, removeToken, setToken } from '@/utils/index.js';
import Cookies from 'js-cookie';
var user = {
  state: {
    user: '',
    roleType: [],
    status: '',
    loginName: '',
    username: '',
    userId: '',
    code: '',
    token: getToken(),
    name: '',
    avatar: '',
    introduction: '',
    roles: [],
    permissions: [],
    permissionInitialized: false,
    ignoreIamPermissionCheck: false,
    customAdmin: '',
    setting: {
      articlePlatform: []
    }
  },
  mutations: {
    SET_CODE: function SET_CODE(state, code) {
      state.code = code;
    },
    SET_TOKEN: function SET_TOKEN(state, token) {
      setToken(token);
      state.token = token;
    },
    SET_INTRODUCTION: function SET_INTRODUCTION(state, introduction) {
      state.introduction = introduction;
    },
    SET_SETTING: function SET_SETTING(state, setting) {
      state.setting = setting;
    },
    SET_STATUS: function SET_STATUS(state, status) {
      state.status = status;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_LOGINNAME: function SET_LOGINNAME(state, loginName) {
      state.loginName = loginName;
    },
    SET_USERNAME: function SET_USERNAME(state, username) {
      state.username = username;
    },
    SET_USERID: function SET_USERID(state, userId) {
      state.userId = userId;
      localStorage.setItem("currentUserId", userId);
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
      // console.log('permissions',permissions);
      // state.permissions = JSON.parse(localStorage.getItem("permissions"));
      state.permissions = permissions;
      state.permissionInitialized = true;
    },
    SET_IGNOREIAMPERMISSION: function SET_IGNOREIAMPERMISSION(state, ignoreIamPermissionCheck) {
      ignoreIamPermissionCheck ? state.ignoreIamPermissionCheck = true : state.ignoreIamPermissionCheck = false;
    },
    SET_ROLETYPE: function SET_ROLETYPE(state, roules) {
      state.roleType = roules;
    },
    SET_CUSTOM_ADMIN: function SET_CUSTOM_ADMIN(state, data) {
      state.customAdmin = data;
    },
    CLEARTOKEN: function CLEARTOKEN(state) {
      Cookies.remove('CASTGC');
    }
  },
  actions: {
    setUserInfo: function setUserInfo(_ref, data) {
      var commit = _ref.commit;
      // const permissions = data.permissions || []
      // console.debug('set user permissions: ', permissions.length)
      // if(!!data.casTgc){
      //   debugegr
      //   Cookies.set("CASTGC",token)
      // }
      Cookies.remove('CASTGC');

      if (localStorage.getItem('authToken')) {
        Cookies.set("CASTGC", localStorage.getItem('authToken'));
      }

      Cookies.set("CASTGC", data.token);
      commit('SET_NAME', data.name);
      commit('SET_LOGINNAME', data.loginName);
      commit('SET_USERNAME', data.username);
      commit('SET_USERID', data.userId); // commit('SET_TOKEN', data.token)

      commit('SET_AVATAR', data.avatar || '');
      commit('SET_INTRODUCTION', data.introduction || '');
      commit('SET_PERMISSIONS', data.permissions || []);
      commit('SET_IGNOREIAMPERMISSION', data.ignoreIamPermissionCheck);
      var role = '';
      var roleType = '';

      try {
        window.MAIAAPM && window.MAIAAPM.setUid(data.username);
      } catch (err) {
        console.log(err && err.message);
      }
    },
    setCustomAdmin: function setCustomAdmin(_ref2, data) {
      var commit = _ref2.commit;
      commit('SET_CUSTOM_ADMIN', data);
    },
    setRoleType: function setRoleType(_ref3, data) {
      var commit = _ref3.commit;
      commit('SET_ROLETYPE', data);
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        commit("CLEARTOKEN");
        removeToken();
        location.reload();
        resolve();
      });
    }
  }
};
export default user;